import { Box, Button, Typography } from "@mui/material";
import React, { useState, useReducer } from "react";
import MonthPicker from "../components/MonthPicker";
import RadioButtons from "../components/buttons/RadioButtons";
import { CustomButton } from "../components/buttons/CustomButton";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import GetReport from "../api/GetReport";
import format from "date-fns/format";
import SnackbarPositive from "../components/snackbars/SnackbarPositive";
import Navbar from "../components/Navbar";
import { CSVLink } from "react-csv";
import { typeImplementation } from "@testing-library/user-event/dist/type/typeImplementation";
const Reports = () => {
  const [pickedDate, setPickedDate] = useState(new Date());
  const [pickedReports, setPickedReports] = useState(1);
  const [reportData, setReportData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "1":
          return {
            ...prevState,
            url: "generate_fina_reports",
            month: action.month,
            year: action.year,
            name: "RaportFINA",
          };
        case "2":
          return {
            ...prevState,
            url: "generate_general_fina_reports",
            month: action.month,
            year: action.year,
            name: "RaportFINAOgolny",
          };
        case "3":
          return {
            ...prevState,
            url: "generate_jpk_report",
            month: action.month,
            year: action.year,
            name: "jpk-",
            type: '"Content-Type": "application/xml; charset=utf-8"',
          };
        case "4":
          return {
            ...prevState,
            url: "generate_prepaidmed_payments",
            month: action.month,
            year: action.year,
            name: "",
          };
        case "5":
          return {
            ...prevState,
            url: "generate_vat_report",
            month: action.month,
            year: action.year,
            name: "Rejestr VAT_",
          };
        default:
          return { ...prevState };
      }
    },
    {
      url: "",
      month: "",
      year: "",
      type: "",
    }
  );

  const handleChangeReport = (event) => {
    let date = format(pickedDate, "yyyy-MM-dd");
    setPickedReports(event.target.value);
    dispatch({
      type: event.target.value,
      month: date.split("-")[1],
      year: date.split("-")[0],
    });
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };
  const handleGetReport = () => {
    setIsLoading(true);
    setIsOpen(false);
    //
    if (state && state.url) {
      GetReport(state.url, state.month, state.year, state.type)
        .then((res) => {
          if (state.name === "jpk-") {
            let file = new Blob(["\ufeff", res.data], {
              type: "application/xml",
            });
            let fileURL = URL.createObjectURL(file);

            setReportData(fileURL);
          } else {
            setReportData(res.data);
          }

          setIsOpen(true);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Box sx={{ height: "100vh" }}>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexGrow: 2,
          justifyContent: "space-evenly",
        }}
      >
        <Typography sx={{ margin: "2rem" }} variant="h2">
          Generator raportów
        </Typography>
        <MonthPicker handleChange={setPickedDate} />
        <RadioButtons
          onChange={handleChangeReport}
          title={"Rodzaj raportu"}
          values={[
            { name: "fina", id: 1 },
            { name: "general_fina", id: 2 },
            { name: "jpk", id: 3 },
            { name: "prepaidmed_payments", id: 4 },
            { name: "vat", id: 5 },
          ]}
        />
        <CustomButton
          label={"Generuj"}
          icon={<NoteAddIcon />}
          handleClick={handleGetReport}
        />
        {reportData &&
          (state.name !== "jpk-" ? (
            <SnackbarPositive
              isOpen={isOpen}
              handleCloseSnackbar={handleCloseSnackbar}
              snackbarMessage={
                <CSVLink
                  style={{
                    textDecoration: "none",
                    background: "white",
                    boxShadow: `0px 6px 10px -5px black`,
                    borderRadius: "25px",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    marginLeft: "1rem",
                    marginRight: "2rem",
                    fontSize: "1.5rem",
                    border: "solid",
                    borderWidth: "2px",
                    borderColor: "transparent",
                    fontFamily: ["Rubik, sans-serif"].join(","),
                    color: "black",
                    fontWeight: "bold",
                  }}
                  data={reportData}
                  filename={`${state.name}${format(pickedDate, "MM_yyyy")}.csv`}
                >
                  Pobierz raport
                </CSVLink>
              }
            />
          ) : (
            <SnackbarPositive
              isOpen={isOpen}
              handleCloseSnackbar={handleCloseSnackbar}
              snackbarMessage={
                <Button
                  sx={{
                    textDecoration: "none",
                    background: "white",
                    boxShadow: `0px 6px 10px -5px black`,
                    borderRadius: "25px",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    marginLeft: "1rem",
                    marginRight: "2rem",
                    fontSize: "1.5rem",
                    border: "solid",
                    borderWidth: "2px",
                    borderColor: "transparent",
                    fontFamily: ["Rubik, sans-serif"].join(","),
                    color: "black",
                    fontWeight: "bold",
                  }}
                  href={`${reportData}`}
                  download={`${state.name}${format(pickedDate, "MM_yyyy")}.xml`}
                >
                  Pobierz raport
                </Button>
              }
            />
          ))}
      </Box>
    </Box>
  );
};

export default Reports;
