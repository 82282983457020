import React, { useState, useContext } from "react";
import { AuthContext } from "../utils/AuthManager";
import { useTheme } from "@emotion/react";
import {
  AppBar,
  Box,
  Fade,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  IconButton,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../utils/fortum_logo_nega.png";
import {
  useNavigate,
  useLocation,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";

const Navbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let location = useLocation();

  return (
    <Box sx={{ flexGrow: 1, maxHeight: "4rem" }}>
      <AppBar
        position="static"
        sx={{ backgroundColor: theme.palette.primary.main }}
      >
        <Toolbar>
          <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}>
            <img
              style={{ maxHeight: "4rem" }}
              src={logo}
              alt="fortum_logo_nega"
            />
          </Typography>
          <IconButton
            onClick={handleClick}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, color: theme.palette.primary.white }}
          >
            <MenuIcon
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            />
          </IconButton>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem
              sx={{ color: "black" }}
              onClick={() => {
                if (location.pathname === "/package") {
                  navigate({
                    pathname: `/zmien-haslo`,
                    search: `${createSearchParams({
                      type: "package",
                    }).toString()}`,
                  });
                } else if (location.pathname === "/accountant") {
                  navigate({
                    pathname: `/zmien-haslo`,
                    search: `${createSearchParams({
                      type: "acc",
                    }).toString()}`,
                  });
                } else {
                  navigate(`/zmien-haslo`);
                }
              }}
            >
              Zmień hasło
            </MenuItem>
            <MenuItem sx={{ color: "black" }} onClick={handleLogout}>
              Wyloguj <LogoutIcon sx={{ marginLeft: "0.5rem" }} />
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Navbar;
