import React, { useState, useEffect, forwardRef } from "react";
import GetClient from "../../api/GetClient";
import GetClientPackages from "../../api/GetClientPackages";
import ClientNavbar from "./ClientNavbar";
import ClientData from "./ClientData";
import ClientPackages from "./ClientPackages";
import { Grid, Slide, Dialog, CircularProgress, Button } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import SnackbarPositive from "../snackbars/SnackbarPositive";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ClientDialog = ({ open, handleClose, clientId }) => {
  const [clientData, setClientData] = useState();
  const [clientPackages, setClientPackages] = useState();
  const [reload, setReload] = useState(false);
  const [snackbarPositiveIsOpen, setSnackbarPositiveIsOpen] = useState(false);
  const [snackbarPositiveMessage, setSnackbarPositiveMessage] = useState();

  const handleOpenSnackbarPositive = (message) => {
    setSnackbarPositiveMessage(message);
    setSnackbarPositiveIsOpen(true);
  };

  const handleCloseSnackbarPositive = () => {
    setSnackbarPositiveIsOpen(false);
  };

  useEffect(() => {
    GetClient(clientId)
      .then((res) => {
        setClientData(res.data);
      })
      .catch((err) => {});
    GetClientPackages(clientId)
      .then((res) => {
        setClientPackages(res.data);
      })
      .catch((err) => {});
  }, [clientId, reload]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      {clientData && (
        <ClientNavbar
          reload={reload}
          setReload={setReload}
          clientId={clientId}
          clientData={clientData}
          close={handleClose}
        />
      )}
      {clientData && clientPackages ? (
        <Grid container>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <ClientData
              clientId={clientId}
              clientData={clientData}
              setClientData={setClientData}
              handleOpenSnackbarPositive={handleOpenSnackbarPositive}
              reload={reload}
              setReload={setReload}
            />
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <ClientPackages
              clientId={clientId}
              handleOpenSnackbarPositive={handleOpenSnackbarPositive}
            />
          </Grid>
        </Grid>
      ) : (
        <CircularProgress
          size={150}
          variant="indeterminate"
          sx={{ position: "absolute", top: "45%", left: "45%" }}
        />
      )}

      <SnackbarPositive
        isOpen={snackbarPositiveIsOpen}
        handleCloseSnackbar={handleCloseSnackbarPositive}
        snackbarMessage={snackbarPositiveMessage}
      />
    </Dialog>
  );
};

export default ClientDialog;
