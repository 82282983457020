import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import pl from "date-fns/locale/pl";

const DayPicker = ({ handleChange, label, future }) => {
  const [value, setValue] = useState(null);
  console.log(future);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
      <DatePicker
        label={label}
        disableFuture={future === "no" && true}
        inputFormat="yyyy-MM-dd"
        value={value}
        onChange={(newValue) => {
          handleChange(newValue);
          setValue(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} sx={{ width: "100%" }} margin="dense" />
        )}
      />
    </LocalizationProvider>
  );
};

export default DayPicker;
