import { api } from "../utils/AuthManager";

const GetCorrectiveInvoice = async (id) => {
  return api
    .get(`/api/generate_corrective_invoice/${id}`, { responseType: "blob" })

    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default GetCorrectiveInvoice;
