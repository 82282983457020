import { api } from "../utils/AuthManager";

const GetClientPackages = async (id) => {
  return api
    .get(`/api/client_package/${id}/`)

    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default GetClientPackages;
