import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
const filter = createFilterOptions();

export default function PackageFinder({
  packagesDict,
  setPackageFormData,
  packageFormData,
  value,
  setValue,
}) {
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue.name === "string") {
          setValue({
            name: newValue.name,
          });
          setPackageFormData({
            ...packageFormData,
            package: newValue.id,
          });
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // const isExisting = options.some((option) => inputValue === option.name);
        // if (inputValue !== "" && !isExisting) {
        //   filtered.push({
        //     inputValue,
        //     title: `Add "${inputValue}"`,
        //   });
        // }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={packagesDict}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // // Add "xxx" option created dynamically
        // if (option.inputValue) {
        //   return option.inputValue;
        // }
        // Regular option
        return option.name;
      }}
      renderOption={(props, option) => (
        <li {...props} style={{ width: "100%" }}>
          {option.name}
        </li>
      )}
      sx={{ width: "100%" }}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label="Przeszukaj wszystko" />
      )}
    />
  );
}
