import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const RadioButtons = ({
  title,
  values,
  onChange,
  row = false,
  helperText,
  isChecked,
  error,
}) => {
  let errorMsg = "";
  if (error) {
    const [message] = error;
    switch (message) {
      case "Family member with Partner życiowy kinship already exists":
        errorMsg = "Istnieje już partner życiowy";
        break;
      case "Dziecko - Family member is already over 26 years old":
        errorMsg = "Dziecko nie może mieć skończone 26 lat";
        break;
      case "This field may not be null.":
        errorMsg = "To pole nie może być puste";
        break;
      default:
        break;
    }
  }

  return (
    <FormControl>
      <FormHelperText>{helperText}</FormHelperText>
      <FormLabel id="demo-radio-buttons-group-label">{title}</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        row={row}
      >
        {values.map((entry, index) => {
          return (
            <FormControlLabel
              onChange={onChange}
              value={entry.id}
              control={<Radio />}
              label={`${entry.name}`}
            />
          );
        })}
      </RadioGroup>
      <FormHelperText error={error ? true : false} sx={{ margin: "0" }}>
        {errorMsg}
      </FormHelperText>
    </FormControl>
  );
};
export default RadioButtons;
