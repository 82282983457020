import { apiPacc } from "../utils/AuthManager";

const GetPackageReport = async (day, month, year) => {
  return apiPacc
    .get(`/api/generate_package_report/${day}/${month}/${year}/`)

    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default GetPackageReport;
