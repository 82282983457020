import { apiAcc } from "../utils/AuthManager";

const GetReport = async (url, month, year, type) => {
  return apiAcc
    .get(`/api/${url}/${month}/${year}/`, {
      type,
    })

    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default GetReport;
