import axios from "axios";
import { url } from "../utils/urls";

const GetIsAccountant = async (token) => {
  return axios
    .get(`${url}/api/is_accountant/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })

    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default GetIsAccountant;
