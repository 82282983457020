import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Input,
  inputAdornmentClasses,
  TextField,
  IconButton,
  CircularProgress,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridFooter,
  GridFooterContainer,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { CustomButton } from "./buttons/CustomButton";
import useAxios from "axios-hooks";
import { url } from "../utils/urls";
import Cookies from "universal-cookie";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../utils/AuthManager";
import FormDialog from "./FormDialog";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { statuses } from "../utils/StatusDict";
import PostPaymentLink from "../api/PostPaymentLink";
import format from "date-fns/format";
import SnackbarPositive from "./snackbars/SnackbarPositive";
import { TableButton } from "./buttons/TableButton";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import ShowAllRadio from "./ShowAllRadio";
const cookies = new Cookies();
const token = cookies.get("token");
const VISIBLE_FIELDS = [
  "client_id",
  "last_name",
  "personal_number",
  "city",
  "packets",
  "link",
  "status",
];

const ArrowDown = styled(ArrowDownwardIcon)(({ theme, active }) => ({
  color: active,
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));
const ArrowUpward = styled(ArrowUpwardIcon)(({ theme, active }) => ({
  color: active,
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const ClientsDataGrid = ({ handleClick, handleClickOpen, reload }) => {
  const [filterModel, setFilterModel] = useState({
    items: [
      {
        columnField: "rating",
        operatorValue: ">",
        value: "2.5",
      },
    ],
  });
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    localStorage.getItem("columns")
      ? JSON.parse(localStorage.getItem("columns"))
      : {
          id: true,

          first_name: true,

          last_name: true,

          pesel: true,

          address_city_name: true,
          link: true,
          packets: true,
          packages_sum: true,

          status: true,
          link_expiration_date: true,
          consultant_name: true,
          phone_number: true,
          insert_date: true,
          paid_date: true,
        }
  );
  const theme = useTheme();
  const [clientsData, setClientsData] = useState([]);
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState({});
  const { logout } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [linkLoading, setLinkLoading] = useState({ status: false, id: null });
  const [searchParams, setSearchParams] = useState({});
  const [sorting, setSorting] = useState("-id");
  const [showAll, setShowAll] = useState(false);

  const handleShowAll = (event) => {
    console.log(event.target.checked);
    setShowAll(event.target.checked);
  };

  const [filterParams, setFilterParams] = useState({
    id: "",

    first_name: "",

    last_name: "",

    pesel: "",

    address_city_name: "",

    packets: "",

    status: "",
    consultant_name: "",
    phone_number: "",
    insert_date: "",
    paid_date: "",
  });
  const [{ data, loading, error }, refetch, cancelRequest] = useAxios({
    url: `${url}/api/main_table/`,
    params: {
      ...pagination,
      ...searchParams,
      show_all: showAll,
      ordering: sorting,
    },
    headers: {
      Authorization: `Token ${cookies.get("token")}`,
    },
  });
  // console.log(data);
  const handleCloseForm = () => {
    setOpenForm(false);
  };
  const handleOpenForm = () => {
    setOpenForm(true);
  };
  const handleSort = (val) => {
    console.log(val);
    setSorting(val);
  };
  const handleOpenSnackbar = () => {
    setIsOpen(true);
  };

  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };
  const handleClear = () => {
    setSearchParams();
    setFilterParams({
      id: "",

      first_name: "",

      last_name: "",

      pesel: "",

      address_city_name: "",

      packets: "",

      status: "",
      link_expiration_date: "",
      consultant_name: "",
      phone_number: "",
      insert_date: "",
      paid_date: "",
    });
  };
  const handleSearch = () => {
    setSearchParams(filterParams);
  };
  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (err) {
      //
      //
      // console.log(err.response);
      if (err.response.status === 401) {
        logout();
      }
    }
  };
  useEffect(() => {
    console.log(columnVisibilityModel);
    localStorage.setItem("columns", JSON.stringify(columnVisibilityModel));
  }, [columnVisibilityModel]);
  useEffect(() => {
    let temp = localStorage.getItem("columns");
    console.log(JSON.parse(temp));
    if (temp) {
      setColumnVisibilityModel(JSON.parse(temp));
    }
    setClientsData([]);
    setPage(0);
    handleRefetch();
  }, [reload]);

  useEffect(() => {
    setPagination({ page: page + 1 });
  }, [page]);

  useEffect(() => {
    if (data && data.results) {
      console.log(data);
      //
      setClientsData(data);
    }
  }, [data]);

  const handleSendLink = (params) => {
    setLinkLoading({ status: true, id: params.row.id });
    PostPaymentLink(params.row.id)
      .then((res) => {
        if (res) {
          handleRefetch();
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLinkLoading({ status: false, id: null });
      });
  };
  const handleCopyLink = async (params) => {
    await navigator.clipboard.writeText(params.row.payment_link_url);
    handleOpenSnackbar();
  };
  const columns = [
    {
      field: "insert_date",
      headerName: "Data wprowadzenia",
      flex: 0.6,
      sortable: false,
      renderCell: (row) => {
        // console.log(row);
        return format(new Date(row.value), "yyyy-MM-dd");
      },
      renderHeader: (row) => {
        return (
          <>
            <input
              style={{ maxWidth: "5rem" }}
              placeholder="Data wprowadzenia"
              value={filterParams.insert_date}
              name="insert_date"
              onKeyDown={(event) => {
                if (event.nativeEvent.code === "Enter") {
                  handleSearch();
                }
              }}
              onChange={(event) => {
                setFilterParams({
                  ...filterParams,
                  [event.target.name]: event.target.value,
                });
              }}
            ></input>
            <IconButton
              onClick={() => {
                if (row.field === sorting) {
                  handleSort(`-${row.field}`);
                } else {
                  handleSort(row.field);
                }
              }}
            >
              {row.field === sorting ? (
                <ArrowUpward
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              ) : (
                <ArrowDown
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              )}
            </IconButton>
          </>
        );
      },
    },
    {
      field: "first_name",
      headerName: "Imię",
      flex: 1,
      sortable: false,
      renderHeader: (row) => {
        return (
          <>
            <input
              placeholder="Imię"
              name="first_name"
              value={filterParams.first_name}
              onKeyDown={(event) => {
                if (event.nativeEvent.code === "Enter") {
                  handleSearch();
                }
              }}
              onChange={(event) => {
                setFilterParams({
                  ...filterParams,
                  [event.target.name]: event.target.value,
                });
              }}
            ></input>
            <IconButton
              onClick={() => {
                if (row.field === sorting) {
                  handleSort(`-${row.field}`);
                } else {
                  handleSort(row.field);
                }
              }}
            >
              {row.field === sorting ? (
                <ArrowUpward
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              ) : (
                <ArrowDown
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              )}
            </IconButton>
          </>
        );
      },
    },
    {
      field: "last_name",
      headerName: "Nazwisko",
      flex: 1,
      sortable: false,
      renderHeader: (row) => {
        return (
          <>
            <input
              placeholder="Nazwisko"
              name="last_name"
              value={filterParams.last_name}
              onKeyDown={(event) => {
                if (event.nativeEvent.code === "Enter") {
                  handleSearch();
                }
              }}
              onChange={(event) => {
                setFilterParams({
                  ...filterParams,
                  [event.target.name]: event.target.value,
                });
              }}
            ></input>
            <IconButton
              onClick={() => {
                if (row.field === sorting) {
                  handleSort(`-${row.field}`);
                } else {
                  handleSort(row.field);
                }
              }}
            >
              {row.field === sorting ? (
                <ArrowUpward
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              ) : (
                <ArrowDown
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              )}
            </IconButton>
          </>
        );
      },
    },
    {
      field: "pesel",
      headerName: "Numer PESEL",
      flex: 0.6,
      sortable: false,
      renderCell: (params) => {
        //
        if (params.value) {
          return params.value;
        } else {
          return "Paszport";
        }
      },
      renderHeader: (row) => {
        return (
          <>
            <input
              placeholder="Pesel"
              type="number"
              name="pesel"
              value={filterParams.pesel}
              onKeyDown={(event) => {
                if (event.nativeEvent.code === "Enter") {
                  handleSearch();
                }
              }}
              onChange={(event) => {
                setFilterParams({
                  ...filterParams,
                  [event.target.name]: event.target.value,
                });
              }}
            ></input>
            <IconButton
              onClick={() => {
                if (row.field === sorting) {
                  handleSort(`-${row.field}`);
                } else {
                  handleSort(row.field);
                }
              }}
            >
              {row.field === sorting ? (
                <ArrowUpward
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              ) : (
                <ArrowDown
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              )}
            </IconButton>
          </>
        );
      },
    },
    {
      field: "address_city_name",
      headerName: "Miasto",
      flex: 1,
      sortable: false,

      renderHeader: (row) => {
        return (
          <>
            <input
              placeholder="Miejscowość"
              name="address_city_name"
              value={filterParams.address_city_name}
              onKeyDown={(event) => {
                if (event.nativeEvent.code === "Enter") {
                  handleSearch();
                }
              }}
              onChange={(event) => {
                setFilterParams({
                  ...filterParams,
                  [event.target.name]: event.target.value,
                });
              }}
            ></input>
            <IconButton
              onClick={() => {
                if (row.field === sorting) {
                  handleSort(`-${row.field}`);
                } else {
                  handleSort(row.field);
                }
              }}
            >
              {row.field === sorting ? (
                <ArrowUpward
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              ) : (
                <ArrowDown
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              )}
            </IconButton>
          </>
        );
      },
    },
    {
      field: "packets",
      headerName: "Dodane pakiety",
      flex: 0.4,
      sortable: false,
      renderHeader: (row) => {
        return (
          <>
            <p>Pakiety</p>
            <IconButton
              onClick={() => {
                if (row.field === sorting) {
                  handleSort(`-${row.field}`);
                } else {
                  handleSort(row.field);
                }
              }}
            >
              {row.field === sorting ? (
                <ArrowUpward
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              ) : (
                <ArrowDown
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              )}
            </IconButton>
          </>
        );
      },
      renderCell: (params) => {
        //
        if (params.formattedValue === 0) {
          return <p style={{ color: theme.palette.error.main }}>Brak</p>;
        } else {
          return params.formattedValue;
        }
      },
    },
    {
      field: "packages_sum",
      headerName: "Suma",
      flex: 0.5,
      sortable: false,
      renderHeader: (row) => {
        return (
          <>
            <p>Suma</p>
            <IconButton
              onClick={() => {
                if (row.field === sorting) {
                  handleSort(`-${row.field}`);
                } else {
                  handleSort(row.field);
                }
              }}
            >
              {row.field === sorting ? (
                <ArrowUpward
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              ) : (
                <ArrowDown
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              )}
            </IconButton>
          </>
        );
      },
      renderCell: (params) => {
        //
        if (params.formattedValue === 0) {
          return <p style={{ color: theme.palette.error.main }}>0</p>;
        } else {
          return `${params.formattedValue} PLN`;
        }
      },
    },
    {
      field: "link",
      headerName: "Przyciski akcji",
      flex: 1,
      sortable: false,

      renderCell: (params) => {
        //
        //
        //
        return (
          <Box sx={{ zIndex: 100, width: "100%" }}>
            <TableButton
              variant={params.row.status}
              loading={
                linkLoading.status === true && linkLoading.id === params.row.id
              }
              handleClick={() => {
                if (params.row.status === 7 || params.row.status === 3) {
                  handleSendLink(params);
                } else if (params.row.status === 4) {
                  handleCopyLink(params);
                }
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => {
        //
        let temp = statuses.find((item) => item.id === params.value);
        if (temp.name === "Link wygasł") {
          return (
            <>
              {temp.name} <PriorityHighIcon sx={{ color: "red" }} />
            </>
          );
        } else {
          return `${temp.name}`;
        }
      },
      renderHeader: (row) => {
        return (
          <>
            <input
              style={{ maxWidth: "5rem" }}
              type="text"
              placeholder="Status"
              name="status"
              value={filterParams.status}
              onKeyDown={(event) => {
                if (event.nativeEvent.code === "Enter") {
                  handleSearch();
                }
              }}
              onChange={(event) => {
                setFilterParams({
                  ...filterParams,
                  [event.target.name]: event.target.value,
                });
              }}
            ></input>
            <IconButton
              onClick={() => {
                if (row.field === sorting) {
                  handleSort(`-${row.field}`);
                } else {
                  handleSort(row.field);
                }
              }}
            >
              {row.field === sorting ? (
                <ArrowUpward
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              ) : (
                <ArrowDown
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              )}
            </IconButton>
          </>
        );
      },
    },
    {
      field: "paid_date",
      headerName: "Data opłacenia",
      flex: 0.7,
      sortable: false,
      renderCell: (row) => {
        if (row.value) {
          return row.value;
        } else {
          return "Nie dotyczy";
        }
      },
      renderHeader: (row) => {
        return (
          <>
            <input
              style={{ maxWidth: "6rem" }}
              type="text"
              placeholder="Data opłacenia"
              name="paid_date"
              value={filterParams.paid_date}
              onKeyDown={(event) => {
                if (event.nativeEvent.code === "Enter") {
                  handleSearch();
                }
              }}
              onChange={(event) => {
                setFilterParams({
                  ...filterParams,
                  [event.target.name]: event.target.value,
                });
              }}
            ></input>
            <IconButton
              onClick={() => {
                if (row.field === sorting) {
                  handleSort(`-${row.field}`);
                } else {
                  handleSort(row.field);
                }
              }}
            >
              {row.field === sorting ? (
                <ArrowUpward
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              ) : (
                <ArrowDown
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              )}
            </IconButton>
          </>
        );
      },
    },
    {
      field: "link_expiration_date",
      headerName: "Ważność linku",
      flex: 0.7,
      sortable: false,
      renderCell: (row) => {
        if (row.value) {
          return row.value;
        } else {
          return "Nie dotyczy";
        }
      },
      renderHeader: (row) => {
        return (
          <>
            <input
              style={{ maxWidth: "6rem" }}
              type="text"
              placeholder="Ważność linku"
              name="link_expiration_date"
              value={filterParams.link_expiration_date}
              onKeyDown={(event) => {
                if (event.nativeEvent.code === "Enter") {
                  handleSearch();
                }
              }}
              onChange={(event) => {
                setFilterParams({
                  ...filterParams,
                  [event.target.name]: event.target.value,
                });
              }}
            ></input>
            <IconButton
              onClick={() => {
                if (row.field === sorting) {
                  handleSort(`-${row.field}`);
                } else {
                  handleSort(row.field);
                }
              }}
            >
              {row.field === sorting ? (
                <ArrowUpward
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              ) : (
                <ArrowDown
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              )}
            </IconButton>
          </>
        );
      },
    },
    {
      field: "phone_number",
      headerName: "Numer telefonu",
      flex: 0.6,
      sortable: false,
      renderHeader: (row) => {
        return (
          <>
            <input
              style={{ maxWidth: "5rem" }}
              placeholder="Numer telefonu"
              value={filterParams.phone_number}
              name="phone_number"
              onKeyDown={(event) => {
                if (event.nativeEvent.code === "Enter") {
                  handleSearch();
                }
              }}
              onChange={(event) => {
                setFilterParams({
                  ...filterParams,
                  [event.target.name]: event.target.value,
                });
              }}
            ></input>
            <IconButton
              onClick={() => {
                if (row.field === sorting) {
                  handleSort(`-${row.field}`);
                } else {
                  handleSort(row.field);
                }
              }}
            >
              {row.field === sorting ? (
                <ArrowUpward
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              ) : (
                <ArrowDown
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              )}
            </IconButton>
          </>
        );
      },
    },
    {
      field: "consultant_name",
      headerName: "Konsultant",
      flex: 0.6,
      sortable: false,
      renderHeader: (row) => {
        return (
          <>
            <input
              style={{ maxWidth: "5rem" }}
              placeholder="Konsultant"
              value={filterParams.consultant_name}
              name="consultant_name"
              onKeyDown={(event) => {
                if (event.nativeEvent.code === "Enter") {
                  handleSearch();
                }
              }}
              onChange={(event) => {
                setFilterParams({
                  ...filterParams,
                  [event.target.name]: event.target.value,
                });
              }}
            ></input>
            <IconButton
              onClick={() => {
                if ("consultant__name" === sorting) {
                  handleSort(`-consultant__name`);
                } else {
                  handleSort("consultant__name");
                }
              }}
            >
              {"consultant__name" === sorting ? (
                <ArrowUpward
                  active={
                    "consultant__name" === sorting ||
                    `-consultant__name` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              ) : (
                <ArrowDown
                  active={
                    "consultant__name" === sorting ||
                    `-consultant__name` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              )}
            </IconButton>
          </>
        );
      },
    },
    {
      field: "id",
      headerName: "ID klienta",
      flex: 0.6,
      sortable: false,
      renderHeader: (row) => {
        return (
          <>
            <input
              style={{ maxWidth: "5rem" }}
              placeholder="ID klienta"
              value={filterParams.id}
              name="id"
              onKeyDown={(event) => {
                if (event.nativeEvent.code === "Enter") {
                  handleSearch();
                }
              }}
              onChange={(event) => {
                setFilterParams({
                  ...filterParams,
                  [event.target.name]: event.target.value,
                });
              }}
            ></input>
            <IconButton
              onClick={() => {
                if (row.field === sorting) {
                  handleSort(`-${row.field}`);
                } else {
                  handleSort(row.field);
                }
              }}
            >
              {row.field === sorting ? (
                <ArrowUpward
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              ) : (
                <ArrowDown
                  active={
                    row.field === sorting || `-${row.field}` === sorting
                      ? theme.palette.primary.main
                      : "#808080"
                  }
                />
              )}
            </IconButton>
          </>
        );
      },
    },
  ];

  if (
    clientsData &&
    clientsData.results &&
    (clientsData.results.length === 0 || clientsData.results.length > 0) &&
    (clientsData.count === 0 || clientsData.count > 0)
  ) {
    return (
      <Box sx={{ height: "100%", width: "100%" }}>
        <DataGrid
          disableColumnMenu
          sx={{ backgroundColor: "white", cursor: "pointer" }}
          components={{
            Footer: function CustoMFooter(props) {
              return (
                <GridFooterContainer
                  {...props}
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ padding: "0.5rem" }}>
                    <Typography>Widoczność klientów</Typography>
                    <ShowAllRadio
                      value={showAll}
                      handleChange={handleShowAll}
                    />
                  </Box>
                  <GridFooter />
                </GridFooterContainer>
              );
            },
            Toolbar: function CustomToolbar(props) {
              return (
                <GridToolbarContainer
                  {...props}
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Button
                      startIcon={<ClearIcon />}
                      onClick={() => {
                        handleClear();
                      }}
                    >
                      Wyczyść filtry
                    </Button>

                    <GridToolbarColumnsButton />
                    <Button onClick={handleSearch} endIcon={<SearchIcon />}>
                      Wyszukaj
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <CustomButton
                      handleClick={handleOpenForm}
                      label="Dodaj klienta"
                      icon={<PersonAddIcon />}
                    />
                  </Box>
                </GridToolbarContainer>
              );
            },
          }}
          rows={clientsData.results}
          columns={columns}
          columnBuffer={14}
          {...clientsData.results}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            setColumnVisibilityModel(newModel);
          }}
          pagination
          page={page}
          loading={loading}
          pageSize={10}
          onRowClick={(row, e) => {
            console.log(e.target.tagName);
            if (
              e.target.tagName !== "BUTTON" &&
              e.target.childNodes[0].tagName !== "BUTTON" &&
              e.target.innerText !== "WYGENERUJ LINK" &&
              e.target.innerText !== "WYGENERUJ PON." &&
              e.target.innerText !== "ANULUJ" &&
              e.target.innerText !== "KOPIUJ"
            ) {
              handleClickOpen(row.id);
            }
          }}
          paginationMode="server"
          rowCount={clientsData.count}
          rowsPerPageOptions={[10]}
          filterModel={filterModel}
          onPageChange={(newPage) => setPage(newPage)}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
        />
        <FormDialog
          handleClose={handleCloseForm}
          open={openForm}
          handleRefetch={handleRefetch}
        />
        <SnackbarPositive
          isOpen={isOpen}
          handleCloseSnackbar={handleCloseSnackbar}
          snackbarMessage={"Skopiowano do schowka"}
        />
      </Box>
    );
  } else {
    if (loading) {
      return (
        <CircularProgress
          size={100}
          sx={{ position: "absolute", top: "50%", left: "50%" }}
        />
      );
    }
  }
};

export default ClientsDataGrid;
