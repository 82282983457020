import { FormControlLabel, Switch } from "@mui/material";

const ShowAllRadio = ({ value, handleChange }) => {
  return (
    <FormControlLabel
      sx={{ color: !value && "#CCC" }}
      label={"Pokaż wszystkich"}
      control={
        <Switch
          checked={value}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
    />
  );
};
export default ShowAllRadio;
