import { api } from "../utils/AuthManager";

const CancelPaymentLink = async (id) => {
  return api
    .post(`/api/cancel_payment_link/${id}/`)

    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default CancelPaymentLink;
