import axios from "axios";
import React, { createContext, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { url } from "../utils/urls.js";
import GetToken from "./../api/GetToken";
import GetIsAccountant from "../api/GetIsAccountant.js";
import GetIsPackageManager from "../api/GetIsPackageManager.js";
const cookies = new Cookies();

export const AuthContext = createContext();

export const api = axios.create({
  baseURL: `${url}`,
});
export const apiAcc = axios.create({
  baseURL: `${url}`,
});
export const apiPacc = axios.create({
  baseURL: `${url}`,
});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [username, setUsername] = useState(
    localStorage.getItem("username") || ""
  );

  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "SIGN_IN":
          return {
            ...prevState,
            isLogged: true,
            token: action.token,
          };
        case "SIGN_IN_ACC":
          return {
            ...prevState,
            isLogged: true,
            accToken: action.token,
          };
        case "SIGN_IN_PACKAGE":
          return {
            ...prevState,
            isLogged: true,
            packageToken: action.token,
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            isLogged: false,
            token: null,
            accToken: null,
            packageToken: null,
          };
        default:
          return { ...prevState };
      }
    },
    {
      isLogged: false,
      token: null,
      accToken: null,
      packageToken: null,
    }
  );
  const accountantLogin = (username, password) => {
    //
    GetToken(username, password)
      .then((res) => {
        GetIsAccountant(res.data.token)
          .then((response) => {
            if (response && response.status === 200) {
              const today = new Date();
              const tomorrow = new Date(today);
              tomorrow.setDate(tomorrow.getDate() + 14);
              cookies.set("tokenAcc", res.data.token, {
                path: "/",
                expires: tomorrow,
              });
              dispatch({ type: "SIGN_IN_ACC", token: res.data.token });
              navigate("/login-accountant");
            } else {
              // Ten else moze byc w sumie totalnie zbedny
              // dispatch({ type: "SIGN_OUT", token: res.data.token });
            }
          })
          .catch((error) => {
            //
          });
      })
      .catch((err) => {
        dispatch({ type: "SIGN_OUT", token: null });
        //
      });
  };
  const packageLogin = (username, password) => {
    //
    GetToken(username, password)
      .then((res) => {
        GetIsPackageManager(res.data.token)
          .then((response) => {
            if (response && response.status === 200) {
              const today = new Date();
              const tomorrow = new Date(today);
              tomorrow.setDate(tomorrow.getDate() + 14);
              cookies.set("tokenPackage", res.data.token, {
                path: "/",
                expires: tomorrow,
              });
              dispatch({ type: "SIGN_IN_PACKAGE", token: res.data.token });
              navigate("/login-package");
            } else {
              // Ten else moze byc w sumie totalnie zbedny
              // dispatch({ type: "SIGN_OUT", token: res.data.token });
            }
          })
          .catch((error) => {
            //
          });
      })
      .catch((err) => {
        dispatch({ type: "SIGN_OUT", token: null });
        //
      });
  };

  const login = (username, password) => {
    GetToken(username, password)
      .then((res) => {
        if (res && res.status === 200) {
          const today = new Date();
          const tomorrow = new Date(today);
          tomorrow.setDate(tomorrow.getDate() + 14);
          cookies.set("token", res.data.token, {
            path: "/",
            expires: tomorrow,
          });

          dispatch({ type: "SIGN_IN", token: res.data.token });
          navigate("/");
        } else {
          // Ten else moze byc w sumie totalnie zbedny
          // dispatch({ type: "SIGN_OUT", token: res.data.token });
        }
      })
      .catch((err) => {
        dispatch({ type: "SIGN_OUT", token: null });
        //
      });
  };

  const restore = () => {
    const token = cookies.get("token");
    const tokenAcc = cookies.get("tokenAcc");
    const tokenPacc = cookies.get("tokenPackage");
    if (token) {
      dispatch({ type: "SIGN_IN", token: token });
    }
    if (tokenAcc) {
      dispatch({ type: "SIGN_IN_ACC", token: tokenAcc });
    }
    if (tokenPacc) {
      dispatch({ type: "SIGN_IN_PACKAGE", token: tokenPacc });
    }
  };

  const logout = () => {
    //
    cookies.remove("token", { path: "/" });
    cookies.remove("tokenAcc", { path: "/" });
    cookies.remove("tokenPackage", { path: "/" });
    dispatch({ type: "SIGN_OUT" });
  };

  api.interceptors.request.use(
    async (config) => {
      const token = cookies.get("token");

      if (token) {
        config.headers.Authorization = `Token ${token}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      //
      if (error && error.response && error.response.status) {
        if (error.response.status === 401) {
          logout();
        }
      }
      return Promise.reject(error);
    }
  );
  apiAcc.interceptors.request.use(
    async (config) => {
      const tokenAcc = cookies.get("tokenAcc");
      if (tokenAcc) {
        config.headers.Authorization = `Token ${tokenAcc}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  apiAcc.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      //
      if (error && error.response && error.response.status) {
        if (error.response.status === 401) {
          logout();
        }
      }
      return Promise.reject(error);
    }
  );
  apiPacc.interceptors.request.use(
    async (config) => {
      const tokenAcc = cookies.get("tokenPackage");
      if (tokenAcc) {
        config.headers.Authorization = `Token ${tokenAcc}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  apiPacc.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      //
      if (error && error.response && error.response.status) {
        if (error.response.status === 401) {
          logout();
        }
      }
      return Promise.reject(error);
    }
  );

  return (
    <AuthContext.Provider
      value={{
        state,
        login,
        restore,
        logout,
        username,
        setUsername,
        accountantLogin,
        packageLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
