import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import PackageFinder from "./PackageFinder";
import { FormGroup, FormControlLabel } from "@mui/material";

const OperatorTabs = ({
  packagesDict,
  setPackageFormData,
  packageFormData,
}) => {
  const [operators, setOperators] = useState({
    luxmed: true,
    medicover: true,
    pzu: true,
    enel: true,
    dentist: true,
  });
  const [value, setValue] = useState(null);
  const parseMeds = () => {
    let arr = [];
    packagesDict[0].package_serializer.map((entry, index) => {
      if (entry.type === 1) {
        arr.push(entry);
      }
    });
    return arr;
  };
  const parseDents = () => {
    let arr = [];
    packagesDict[0].package_serializer.map((entry, index) => {
      if (entry.type === 2) {
        arr.push(entry);
      }
    });
    return arr;
  };
  const parseDict = () => {
    let arr = [];
    let newArr = arr.concat(
      operators.luxmed === true && parseMeds(),
      operators.medicover === true && packagesDict[1].package_serializer,
      operators.pzu === true && packagesDict[2].package_serializer,
      operators.enel === true && packagesDict[3].package_serializer,
      operators.dentist === true && parseDents()
    );

    return newArr;
  };

  const handleChange = (event) => {
    setValue(null);
    setPackageFormData({ ...packageFormData, package: "" });
    setOperators({ ...operators, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    setPackageFormData({ ...packageFormData, package: "" });
  }, [operators]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          flexDirection: "column",
          pb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
            <FormControlLabel
              label="LUXMED"
              control={
                <Checkbox
                  name="luxmed"
                  checked={operators.luxmed}
                  onChange={handleChange}
                />
              }
            />

            <FormControlLabel
              label="MEDICOVER"
              control={
                <Checkbox
                  name="medicover"
                  checked={operators.medicover}
                  onChange={handleChange}
                />
              }
            />
            <FormControlLabel
              label="PZU ZDROWIE"
              control={
                <Checkbox
                  name="pzu"
                  checked={operators.pzu}
                  onChange={handleChange}
                />
              }
            />
            <FormControlLabel
              label="ENEL-MED"
              control={
                <Checkbox
                  name="enel"
                  checked={operators.enel}
                  onChange={handleChange}
                />
              }
            />
            <FormControlLabel
              label="Stomatologia"
              control={
                <Checkbox
                  name="dentist"
                  checked={operators.dentist}
                  onChange={handleChange}
                />
              }
            />
          </FormGroup>
        </Box>
        <Box sx={{ mt: 1 }}>
          <PackageFinder
            value={value}
            setValue={setValue}
            packagesDict={parseDict()}
            setPackageFormData={setPackageFormData}
            packageFormData={packageFormData}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OperatorTabs;
