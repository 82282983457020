import { api } from "../utils/AuthManager";

const PostClient = async (formData) => {
  return api
    .post(`/api/client/`, formData)

    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default PostClient;
