import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import pl from "date-fns/locale/pl";
import format from "date-fns/format";

const MonthPicker = ({ handleChange }) => {
  const [value, setValue] = useState(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
      <DatePicker
        views={["month", "year"]}
        // maxDate={format(new Date(), "yyyy-MM-dd")}
        label="Miesiąc raportowy"
        inputFormat="yyyy-MM-dd"
        value={value}
        onChange={(newValue) => {
          handleChange(newValue);
          setValue(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} sx={{ width: "20rem" }} margin="dense" />
        )}
      />
    </LocalizationProvider>
  );
};

export default MonthPicker;
