import { Box, Typography } from "@mui/material";
import React, { useState, useReducer } from "react";
import MonthPicker from "../components/MonthPicker";
import RadioButtons from "../components/buttons/RadioButtons";
import { CustomButton } from "../components/buttons/CustomButton";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import GetReport from "../api/GetReport";
import format from "date-fns/format";
import SnackbarPositive from "../components/snackbars/SnackbarPositive";
import Navbar from "../components/Navbar";
import { CSVLink } from "react-csv";
import GetPackageReport from "../api/GetPackageReport";
const PackageReports = () => {
  const [pickedDate, setPickedDate] = useState(new Date());
  const [reportData, setReportData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [reportDate, setReportDate] = useState();

  const handleChangeReport = (val) => {
    setPickedDate(val);

    let date = format(val, "yyyy-MM-dd");
    setReportDate({
      day: date.split("-")[2],
      month: date.split("-")[1],
      year: date.split("-")[0],
    });
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };
  const handleGetReport = () => {
    setIsLoading(true);
    setIsOpen(false);

    if (reportDate) {
      GetPackageReport(
        parseInt(reportDate.day),
        parseInt(reportDate.month),
        parseInt(reportDate.year)
      )
        .then((res) => {
          setReportData(res.data);

          setIsOpen(true);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  return (
    <Box sx={{ height: "100vh" }}>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexGrow: 2,
          justifyContent: "space-evenly",
        }}
      >
        <Typography sx={{ margin: "2rem" }} variant="h2">
          Generator raportów
        </Typography>
        <MonthPicker handleChange={handleChangeReport} />
        <CustomButton
          label={"Generuj"}
          icon={<NoteAddIcon />}
          handleClick={handleGetReport}
        />
        {reportData && (
          <SnackbarPositive
            isOpen={isOpen}
            handleCloseSnackbar={handleCloseSnackbar}
            snackbarMessage={
              <CSVLink
                style={{
                  textDecoration: "none",
                  background: "white",
                  boxShadow: `0px 6px 10px -5px black`,
                  borderRadius: "25px",
                  paddingTop: "0.2rem",
                  paddingBottom: "0.2rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  marginLeft: "1rem",
                  marginRight: "2rem",
                  fontSize: "1.5rem",
                  border: "solid",
                  borderWidth: "2px",
                  borderColor: "transparent",
                  fontFamily: ["Rubik, sans-serif"].join(","),
                  color: "black",
                  fontWeight: "bold",
                }}
                data={reportData}
                filename={`Raport pakiety - ${format(
                  pickedDate,
                  "dd_MM_yyyy"
                )}.csv`}
              >
                Pobierz raport
              </CSVLink>
            }
          />
        )}
      </Box>
    </Box>
  );
};

export default PackageReports;
