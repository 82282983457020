import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  List,
  ListItem,
  Typography,
  Divider,
  Button,
  CircularProgress,
  LinearProgress,
  IconButton,
} from "@mui/material";
import Navbar from "../components/Navbar";
import { useTheme } from "@emotion/react";
import { useParams } from "react-router-dom";
import GetClient from "../api/GetClient";
import { statuses } from "../utils/StatusDict";
import styled from "@emotion/styled";
import BankForm from "../components/BankForm";
import DayPicker from "../components/DayPicker";
import PostCorrectiveInvoice from "../api/PostCorrectiveInvoice";
import GetCorrectiveInvoice from "../api/GetCorrectiveInvoice";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
}));
const Documents = () => {
  const theme = useTheme();
  const [clientData, setClientData] = useState();
  const [currentStatus, setCurrentStatus] = useState();
  const [pickedDate, setPickedDate] = useState();
  const [renouncing, setRenouncing] = useState(false);
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState();
  const [reload, setReload] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [generatingInvoice, setGeneratingInvoice] = useState(false);
  const navigate = useNavigate();
  const { clientId } = useParams();
  console.log(clientData);
  useEffect(() => {
    setStatus(0);
    GetClient(clientId)
      .then((res) => {
        setClientData(res.data);
        let temp = statuses.find((el) => el.id === res.data.status);
        setCurrentStatus(temp);
      })
      .catch((err) => console.log(err));
  }, [clientId, reload]);

  const handleChangeDate = (val) => {
    setPickedDate(val);
  };
  const handleCorrection = (val) => {
    setStatus(0);
    setLoading(true);
    PostCorrectiveInvoice(clientId, pickedDate)
      .then((res) => setStatus(201))
      .catch((err) => setStatus(400))
      .finally(() => {
        setRenouncing(false);
        setLoading(false);
      });
  };
  const handleGetCorrectiveInvoice = () => {
    setGeneratingInvoice(true);
    GetCorrectiveInvoice(clientId)
      .then((res) => {
        let fileURL = URL.createObjectURL(res.data);
        let alink = document.createElement("a");

        alink.href = fileURL;
        alink.download = `${clientId}`;
        alink.click();
      })
      .finally(() => setGeneratingInvoice(false));
  };
  return (
    <Grid
      container
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: theme.palette.primary.background,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
      }}
    >
      <Navbar />
      <Box
        sx={{
          flexGrow: 2,
          padding: "1rem",
          backgroundColor: "white",
          width: "40rem",
          margin: "2rem",
          borderRadius: "25px",
          boxShadow: "-1px 8px 24px -14px rgba(66, 68, 90, 1)",
          alignSelf: "center",
        }}
      >
        <Button startIcon={<ArrowBackIosIcon />} onClick={() => navigate("/")}>
          Powrót do tabeli głównej
        </Button>
        {clientData && (
          <>
            <List>
              <StyledListItem>
                <Typography variant="p">Imię i nazwisko</Typography>

                <Typography variant="h4">
                  {clientData.first_name} {clientData.last_name}
                </Typography>
              </StyledListItem>
              <Divider />
              <StyledListItem>
                <Typography variant="p">Email do kontaktu</Typography>

                <Typography variant="h4">{clientData.contact_email}</Typography>
              </StyledListItem>
              <Divider />
              <StyledListItem>
                <Typography variant="p">Email do faktury</Typography>

                <Typography variant="h4">{clientData.invoice_email}</Typography>
              </StyledListItem>
              <Divider />
              <StyledListItem>
                <Typography variant="p">Status klienta</Typography>

                <Typography variant="h4">{currentStatus.name}</Typography>
              </StyledListItem>
              <Divider />
              <StyledListItem>
                <Typography variant="p">Czy może odstąpić?</Typography>

                <Typography variant="h4">
                  {clientData.can_renounce ? "TAK" : "NIE"}
                </Typography>
              </StyledListItem>
              <Divider />
              {clientData.bank_number && !editMode && (
                <>
                  <StyledListItem>
                    <Typography variant="p">Numer rachunku</Typography>

                    <Typography variant="h4">
                      <IconButton onClick={() => setEditMode(true)}>
                        <EditIcon />
                      </IconButton>
                      {clientData.bank_number}
                    </Typography>
                  </StyledListItem>
                  <Divider />
                </>
              )}
              {clientData.bank_name && !editMode && (
                <>
                  <StyledListItem>
                    <Typography variant="p">Nazwa banku</Typography>

                    <Typography variant="h4">{clientData.bank_name}</Typography>
                  </StyledListItem>
                  <Divider />
                </>
              )}
            </List>
            {editMode && (
              <IconButton onClick={() => setEditMode(false)}>
                <ClearIcon />
              </IconButton>
            )}
            {((!clientData.bank_name && !clientData.bank_number) ||
              editMode) && (
              <BankForm setEditMode={setEditMode} setReload={setReload} />
            )}
            {clientData.can_renounce && (
              <DayPicker
                handleChange={handleChangeDate}
                label="Data odstąpienia"
                future={"no"}
              />
            )}
            {!renouncing &&
              clientData.can_renounce &&
              clientData.bank_name &&
              clientData.bank_number &&
              status === 0 && (
                <Button onClick={() => setRenouncing(true)}>Odstąp</Button>
              )}
            {renouncing && (
              <Button onClick={handleCorrection}>Potwierdź</Button>
            )}{" "}
            {loading && <CircularProgress size={20} />}
            <Typography
              variant="p"
              sx={{
                color: "red",
              }}
            >
              {status === 400 ? "Odstąpienie nieudane" : ""}
            </Typography>
            {(status === 201 ||
              (clientData.status === 8 && !generatingInvoice)) && (
              <Button
                variant="contained"
                onClick={() => {
                  handleGetCorrectiveInvoice();
                }}
              >
                Pobierz korektę
              </Button>
            )}
            {generatingInvoice && (
              <>
                <Typography variant="p">Trwa generowanie pliku...</Typography>
                <LinearProgress />
              </>
            )}
          </>
        )}
      </Box>
    </Grid>
  );
};

export default Documents;
