import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthContext, AuthProvider } from "./utils/AuthManager";
import { plPL } from "@mui/x-data-grid";
import LoginReports from "./pages/LoginReports";
import Reports from "./pages/Reports";
import LoginPackage from "./pages/LoginPackage";
import PackageReports from "./pages/PackageReports";
import PasswordChange from "./pages/PasswordChange";
import Documents from "./pages/Documents";
const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#66CC66",
        background: "#E4E4E4",
        white: "white",
        black: "black",
      },
    },
  },
  plPL
);

theme.typography.h1 = {
  fontSize: "2.5rem",
  fontStyle: "normal",
  fontWeight: "bold",
  color: theme.palette.primary.black,
  textAlign: "center",
  fontFamily: ["Rubik, sans-serif"].join(","),
};
theme.typography.h2 = {
  fontSize: "2rem",
  fontStyle: "normal",
  fontWeight: "bold",
  color: theme.palette.primary.black,
  textAlign: "center",
  fontFamily: ["Rubik, sans-serif"].join(","),
};
theme.typography.h3 = {
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "bold",
  color: theme.palette.primary.white,
  fontFamily: ["Rubik, sans-serif"].join(","),
};
theme.typography.h4 = {
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "bold",
  color: theme.palette.primary.black,
  fontFamily: ["Rubik, sans-serif"].join(","),
};
theme.typography.p = {
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "regular",
  color: theme.palette.primary.black,
  textAlign: "center",
  fontFamily: ["Rubik, sans-serif"].join(","),
};
const App = () => {
  const { restore, state } = React.useContext(AuthContext);

  const [isTokenRestored, setIsTokenRestored] = React.useState(false);
  const [isTokenRestoredAcc, setIsTokenRestoredAcc] = React.useState(false);

  useEffect(() => {
    restore();
    setIsTokenRestored(true);
  }, []);

  return (
    <>
      {isTokenRestored && (
        <Routes>
          <Route
            path="/login"
            element={state.token === null ? <Login /> : <Navigate to="/" />}
          />
          <Route
            path="/"
            element={
              state.token !== null ? <Dashboard /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/client/:clientId"
            element={
              state.token !== null ? <Documents /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/zmien-haslo"
            element={
              state.token !== null ||
              state.accToken === null ||
              state.packageToken === null ? (
                <PasswordChange />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/login-accountant"
            element={
              state.accToken === null ? (
                <LoginReports />
              ) : (
                <Navigate to="/accountant" />
              )
            }
          />
          <Route
            path="/accountant"
            element={
              state.accToken !== null ? (
                <Reports />
              ) : (
                <Navigate to="/login-accountant" />
              )
            }
          />
          <Route
            path="/login-package"
            element={
              state.packageToken === null ? (
                <LoginPackage />
              ) : (
                <Navigate to="/package" />
              )
            }
          />
          <Route
            path="/package"
            element={
              state.packageToken !== null ? (
                <PackageReports />
              ) : (
                <Navigate to="/login-package" />
              )
            }
          />
        </Routes>
      )}
    </>
  );
};

export default () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};
