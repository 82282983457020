import React, { useState, useContext } from "react";
import { Box, Grid, TextField, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { AuthContext } from "../utils/AuthManager";
import logo from "../utils/fortum_logo_nega.png";
const Login = () => {
  const { username, setUsername, login } = useContext(AuthContext);
  const [password, setPassword] = useState();

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    login(username, password);
    localStorage.setItem("username", username);
  };

  return (
    <Grid container sx={{ width: "100vw", height: "100vh" }}>
      <Grid item lg={6} md={6} sx={{ backgroundColor: "#66CC66" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <img
            src={logo}
            alt="fortum_logo_nega"
            style={{ maxHeight: "10rem" }}
          />
        </Box>
      </Grid>
      <Grid item lg={6} md={6} sx={{ backgroundColor: "#ffff" }}>
        <Box
          component="form"
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            sx={{ margin: "0.5rem", width: "20rem" }}
            id="outlined-name"
            label="Login"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            type="password"
            sx={{ margin: "0.5rem", width: "20rem" }}
            id="outlined-name"
            label="Hasło"
            value={password}
            onChange={handleChangePassword}
          />
          <Button
            sx={{ backgroundColor: "#66CC66", width: "20rem" }}
            type="submit"
            onClick={(event) => {
              event.preventDefault();
              handleLogin();
            }}
          >
            <Typography variant="h3">Zaloguj się</Typography>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
