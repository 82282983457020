import React, { useEffect, useState } from "react";
import { Box, Grid} from "@mui/material";
import { useTheme } from "@emotion/react";
import Navbar from "../components/Navbar";
import ClientsDataGrid from "../components/ClientsDataGrid";
import ClientDialog from "../components/client-dialog/ClientDialog";
import SnackbarPositive from "../components/snackbars/SnackbarPositive";

const Dashboard = () => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [clientId, setClientId] = useState();
  const [reload, setReload] = useState();

  useEffect(() => {
    setClientId();
  }, []);

  const handleClickOpen = (value) => {
    setOpen(true);
    setClientId(value);
  };

  const handleClose = () => {
    setOpen(false);
    setClientId();
    setReload((prev) => !prev);
  };

  return (
    <Grid
      container
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: theme.palette.primary.background,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
      }}
    >
      <Navbar />
      <Box sx={{ flexGrow: 2, padding: "1rem" }}>
        <ClientsDataGrid reload={reload} handleClickOpen={handleClickOpen} />

        {clientId && (
          <ClientDialog
            clientId={clientId}
            open={open}
            handleClose={handleClose}
          />
        )}
      </Box>
      <SnackbarPositive/>
    </Grid>
  );
};

export default Dashboard;
