import styled from "@emotion/styled";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SaveIcon from "@mui/icons-material/Save";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { CircularProgress } from "@mui/material";

const StyledButton = styled(Button)(({ theme, width, error }) => ({
  backgroundColor: error ? "red" : "#66CC66",
  color: "white",
  width: width,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
  "&:hover": {
    backgroundColor: error ? "red" : "#5BB75B",
  },
}));
export const CustomButton = ({
  handleClick,
  width,
  type,
  disabled,
  label,
  icon,
  error,
  loading,
}) => {
  return (
    <Box
      sx={{
        textAlign: "right",
        variant: "contained",
        color: "success",
      }}
    >
      <StyledButton
        error={error}
        disabled={disabled}
        endIcon={icon}
        onClick={handleClick}
        width={width}
      >
        {label}
        {loading && (
          <CircularProgress
            size={25}
            sx={{
              position: "absolute",
              top: "20%",
              left: "40%",
              color: "white",
            }}
          />
        )}
      </StyledButton>
    </Box>
  );
};
