import React from "react";
import { useTheme } from "@emotion/react";
import {
  Snackbar,
  Alert,
  IconButton,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomButton } from "../buttons/CustomButton";

const SnackbarDelete = ({
  isOpen,
  handleCloseSnackbar,
  snackbarMessage,
  callbackOnConfirm,
  loading,
  disabled,
}) => {
  const theme = useTheme();

  const action = (
    <IconButton
      onClick={handleCloseSnackbar}
      sx={{
        margin: "0",
        padding: "0",
      }}
    >
      <CloseIcon />
    </IconButton>
  );

  return (
    <div>
      <Snackbar
        open={isOpen}
        autoHideDuration={15000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          severity="error"
          sx={{
            display: "flex",
            gap: "10px",
          }}
          action={action}
        >
          {snackbarMessage}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <CustomButton
              label="Potwierdź"
              disabled={disabled}
              loading={loading}
              handleClick={callbackOnConfirm}
            ></CustomButton>
          </Box>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackbarDelete;
