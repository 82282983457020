import { api } from "../utils/AuthManager";

const DeleteFamilyMemberPackage = async (id) => {
  return api
    .delete(`/api/family_member_package/${id}/`)

    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default DeleteFamilyMemberPackage;
