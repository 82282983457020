import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { CircularProgress } from "@mui/material";
import AddLinkIcon from "@mui/icons-material/AddLink";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useEffect, useState, useReducer } from "react";
import DoneIcon from "@mui/icons-material/Done";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import CancelIcon from "@mui/icons-material/Cancel";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
const StyledButton = styled(Button)(({ theme, width, error, bg, font }) => ({
  backgroundColor: bg,
  color: "white",
  width: width,
  fontSize: font,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
  "&:hover": {
    backgroundColor: bg,
    opacity: 0.7,
  },
  "&.Mui-disabled": {
    color: "white",
    opacity: 1,
  },
}));
export const TableButton = ({ handleClick, variant, loading }) => {
  const [buttonType, setButtonType] = useState();

  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 4:
          return {
            ...prevState,
            bg: "#9C9C9C",
            disabled: false,
            error: false,
            label: "Kopiuj link",
            font: "0.8rem",
            icon: <ContentCopyIcon />,
          };
        case 7:
          return {
            ...prevState,
            bg: "orange",
            disabled: false,
            error: false,
            font: "0.8rem",
            label: "Wygeneruj pon.",
            icon: <RefreshIcon />,
          };
        case 8:
          return {
            ...prevState,
            bg: "#CCC",
            disabled: true,
            error: false,
            font: "0.8rem",
            label: "Odstąpiono",
            icon: <NoAccountsIcon />,
          };
        case 5:
          return {
            ...prevState,
            bg: "#66CC66",
            disabled: true,
            error: false,
            font: "0.8rem",
            label: "Opłacony",
            icon: <DoneIcon />,
          };
        case 2:
          return {
            ...prevState,
            bg: "red",
            disabled: true,
            error: false,
            font: "0.8rem",
            label: "Brak pakietu",
            icon: <DoDisturbIcon />,
          };
        case 3:
          return {
            ...prevState,
            bg: "#6BA6FF",
            disabled: false,
            error: false,
            font: "0.8rem",
            label: "Wygeneruj link",
            icon: <AddLinkIcon />,
          };
        case "cancel":
          return {
            ...prevState,
            bg: "red",
            disabled: false,
            error: true,
            font: "0.8rem",
            label: "Anuluj link",
            icon: <CancelIcon />,
          };
        default:
          return { ...prevState };
      }
    },
    {
      bg: "#c9c9c9",
      disabled: false,
      error: false,
      label: "---",
    }
  );

  useEffect(() => {
    dispatch({ type: variant });
  }, [variant]);
  return (
    <Box
      sx={{
        textAlign: "right",
        variant: "contained",
        color: "success",
      }}
    >
      <StyledButton
        error={state.error}
        disabled={loading ? true : state.disabled}
        endIcon={state.icon}
        onClick={handleClick}
        width={"100%"}
        bg={state.bg}
        font={state.font}
      >
        {state.label}
        {loading && (
          <CircularProgress
            size={25}
            sx={{
              position: "absolute",
              top: "20%",
              left: "40%",
              color: "white",
            }}
          />
        )}
      </StyledButton>
    </Box>
  );
};
