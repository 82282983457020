import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import PutPassword from "../api/PutPassword";
import { useNavigate, useSearchParams } from "react-router-dom";
const PasswordChange = () => {
  const theme = useTheme();
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [newPasswordConfirm, setNewPasswordConfirm] = useState();
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [type, setType] = useState();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      setType(value);
    }
  }, [searchParams]);
  const navigate = useNavigate();
  const handlePutPassword = () => {
    setPasswordChanged(false);
    setError("");
    setLoading(true);
    let formData = {
      old_password: oldPassword,
      new_password1: newPassword,
      new_password2: newPasswordConfirm,
    };
    PutPassword(formData, type)
      .then((res) => {
        setPasswordChanged(true);
        setNewPassword("");
        setNewPasswordConfirm("");
        setOldPassword("");
      })
      .catch((err) => {
        setError(
          err.response.data.error.details.old_password
            ? err.response.data.error.details.old_password.old_password ===
                "Old password is not correct" &&
                "Podane stare hasło jest błędne"
            : err.response.data.error.details.non_field_errors
            ? "Hasło musi składać się z conajmniej 8 znaków"
            : err.response.data.error.details.new_password2[0] ===
                "Password fields don't match" && "Hasła nie są zgodne"
        );
      })
      .finally(() => setLoading(false));
  };
  return (
    <Grid
      container
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: theme.palette.primary.background,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handlePutPassword();
        }}
        sx={{
          height: "30rem",
          width: "30rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          backgroundColor: theme.palette.primary.main,
          borderRadius: "25px",
        }}
      >
        <Typography variant="h2">Ustaw nowe hasło</Typography>{" "}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <TextField
            sx={{ margin: "0.5rem", width: "20rem", backgroundColor: "white" }}
            id="outlined-name"
            label="Stare hasło"
            value={oldPassword}
            variant="filled"
            type="password"
            required={true}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <TextField
            sx={{ margin: "0.5rem", width: "20rem", backgroundColor: "white" }}
            id="outlined-name"
            label="Nowe hasło"
            value={newPassword}
            variant="filled"
            required={true}
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            sx={{ margin: "0.5rem", width: "20rem", backgroundColor: "white" }}
            id="outlined-name"
            variant="filled"
            label="Powtórz nowe hasło"
            required={true}
            value={newPasswordConfirm}
            type="password"
            onChange={(e) => setNewPasswordConfirm(e.target.value)}
          />
          <Typography variant="p" sx={{ color: "red" }}>
            {error}
          </Typography>
          {passwordChanged && (
            <Typography variant="p" sx={{ color: "white" }}>
              Hasło zmienione pomyślnie!
            </Typography>
          )}
          {!passwordChanged ? (
            <Button
              type="submit"
              variant="outlined"
              disabled={loading}
              sx={{
                backgroundColor: "white",
                borderRadius: "25px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
              Zmień hasło
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={() => {
                if (type === "package") {
                  navigate("/package");
                } else if (type === "acc") {
                  navigate("/accountant");
                } else {
                  navigate("/");
                }
              }}
              sx={{
                backgroundColor: "white",
                borderRadius: "25px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
              Przejdź do ekrany głównego
            </Button>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default PasswordChange;
