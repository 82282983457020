import { api } from "../utils/AuthManager";
import format from "date-fns/format";
const PostCorrectiveInvoice = async (id, date) => {
  return api
    .post(
      `/api/create_corrective_invoice/${id}`,
      {},
      {
        params: {
          date: format(new Date(date), "yyyy-MM-dd"),
        },
      }
    )

    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default PostCorrectiveInvoice;
