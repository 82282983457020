import { api, apiPacc, apiAcc } from "../utils/AuthManager";

const PutPassword = async (formData, searchParam) => {
  if (searchParam === "package") {
    return apiPacc
      .put(`/api/change_password/`, formData)

      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else if (searchParam === "acc") {
    return apiAcc
      .put(`/api/change_password/`, formData)

      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return api
      .put(`/api/change_password/`, formData)

      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
};

export default PutPassword;
