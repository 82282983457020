import React, { useState } from 'react'
import { useTheme } from "@emotion/react";
import { Snackbar, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SnackbarPositive = ({isOpen, handleCloseSnackbar, snackbarMessage}) => {
  const theme = useTheme();

  const action = (
    <IconButton onClick={handleCloseSnackbar}><CloseIcon/></IconButton>
  )

  const message = (
    <Typography variant='h5'>{snackbarMessage}</Typography>
  )

  return (
    <div>
      <Snackbar 
      open={isOpen} 
      ContentProps={{
          sx: { 
            backgroundColor: theme.palette.primary.main, 
            color: "white", 
            display: 'flex',
            justifyContent: 'center'
          },
        }} 
        message={message}
        action={action}
        autoHideDuration={15000}
        onClose={handleCloseSnackbar}
        />

    </div>
  )
}

export default SnackbarPositive
