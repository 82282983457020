export const statuses = [
  { id: 1, name: "Anulowany" },
  { id: 2, name: "Brak pakietu" },
  { id: 3, name: "Brak linku" },
  { id: 4, name: "Nieopłacony" },
  { id: 5, name: "Opłacony" },
  { id: 6, name: "Przekazany" },
  { id: 7, name: "Link wygasł" },
  { id: 8, name: "Odstąpiono" },
];
