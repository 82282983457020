import styled from "@emotion/styled";
import {
  Button,
  TextField,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import CheckBankName from "../api/CheckBankName";
import PatchBankData from "../api/PatchBankData";
import { useParams } from "react-router-dom";
const StyledTextField = styled(TextField)(({ theme }) => ({
  minWidth: "10rem",
  width: "100%",
  margin: "0.5rem",
}));

const BankForm = ({ setReload, setEditMode }) => {
  const [bankNumber, setBankNumber] = useState();
  const [bankName, setBankName] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { clientId } = useParams();
  const handleCheckBank = () => {
    setLoading(true);
    setError("");
    CheckBankName(bankNumber)
      .then((res) => setBankName(res.data.bank_name))
      .catch((err) => {
        console.log(err);
        setError(err.response.data.error);
      })
      .finally(() => {
        setReload((prev) => !prev);
        setLoading(false);
      });
  };

  const handleSaveBankData = () => {
    PatchBankData(clientId, {
      bank_number: bankNumber.replace(/\s/g, ""),
      bank_name: bankName,
    }).then(() => {
      setEditMode(false);
      setReload((prev) => !prev);
    });
  };
  return (
    <>
      <StyledTextField
        size="small"
        label="Numer rachunku"
        variant="outlined"
        id="outlined-error"
        helperText={
          error === "Bank number validation error" && "Błędny numer rachunku"
        }
        error={error === "Bank number validation error" && true}
        value={bankNumber}
        onChange={(event) => {
          setBankNumber(event.target.value);
        }}
      />

      <Button disabled={loading} onClick={() => handleCheckBank()}>
        Pobierz nazwę banku
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              //   color: ,
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Button>
      {bankName && (
        <StyledTextField
          size="small"
          label="Numer rachunku"
          variant="outlined"
          id="outlined-error"
          value={bankName}
          onChange={(event) => {
            setBankName(event.target.value);
          }}
        />
      )}
      {bankName && bankNumber && (
        <Button disabled={loading} onClick={() => handleSaveBankData()}>
          Zapisz dane banku
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                //   color: ,
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Button>
      )}
    </>
  );
};

export default BankForm;
