import React, { useState, useEffect } from "react";
import GetPackagesDict from "../../api/GetPackagesDict";
import GetClientPackages from "../../api/GetClientPackages";
import PostClientPackage from "../../api/PostClientPackage";
import DeleteClientPackage from "../../api/DeleteClientPackage";
import DeleteFamilyMemberPackage from "../../api/DeleteFamilyMemberPackage";
import { useTheme } from "@emotion/react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  List,
  ListItemButton,
  ListItemText,
  ListItem,
  Box,
  Collapse,
  Grid,
  Divider,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EastIcon from "@mui/icons-material/East";
import SaveIcon from "@mui/icons-material/Save";
import styled from "@emotion/styled";
import format from "date-fns/format";
import DayPicker from "../DayPicker";
import OperatorTabs from "../OperatorTabs";
import { CustomButton } from "../buttons/CustomButton";
import SnackbarDelete from "../snackbars/SnackbarDelete";
import FamilyMemberDialog from "./FamilyMemberDialog";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const ClientPackages = ({ clientId, handleOpenSnackbarPositive }) => {
  const theme = useTheme();
  const [openList, setOpenList] = useState();
  const [clientPackages, setClientPackages] = useState([]);
  const [openFamilyMember, setOpenFamilyMember] = useState(false);
  const [packagesDict, setPackagesDict] = useState("");
  const [packageFormData, setPackageFormData] = useState({
    service_location: "",
    package: "",
    start_date: "",
    family_member_packages: [],
  });
  const [addPackage, setAddPackage] = useState(false);
  const [deletePackageSnackbar, setDeletePackageSnackbar] = useState(false);
  const [deleteFamilyMemberSnackbar, setDeleteFamilyMemberSnackbar] =
    useState(false);
  const [packageId, setPackageId] = useState();
  const [familyMemberId, setFamilyMemberId] = useState();
  const [reload, setReload] = useState();
  const [packageLoading, setPackageLoading] = useState(false);

  const handleOpenAddPackage = () => {
    setAddPackage(true);
    GetPackagesDict()
      .then((res) => {
        setPackagesDict(res.data);
      })
      .catch((err) => {});
  };

  const handleOpenList = (value) => {
    if (openList === value) {
      setOpenList(null);
    } else {
      setOpenList(value);
    }
  };

  const handlePostClientPackage = () => {
    let newData = {
      ...packageFormData,
      start_date: format(packageFormData.start_date, "yyyy-MM-dd"),
    };
    setPackageLoading(true);
    PostClientPackage(clientId, newData)
      .then((res) => {
        if (res.status === 201) {
          setAddPackage(false);
          handleOpenSnackbarPositive("Pakiet dodany pomyślnie");
          setReload((prev) => !prev);
          setPackageFormData({ ...packageFormData, service_location: "" }); // Reset City Textfield
        }
      })
      .catch((err) => {})
      .finally(() => setPackageLoading(false));
  };

  const handleChangeDate = (val) => {
    setPackageFormData({
      ...packageFormData,
      start_date: val,
    });
  };

  const handleCloseFamilyMember = () => {
    setOpenFamilyMember(false);
  };

  const handleClickOpenFamilyMember = (val) => {
    setOpenFamilyMember(true);
    setPackageId(val);
  };

  useEffect(() => {
    GetClientPackages(clientId)
      .then((res) => {
        setClientPackages(res.data);
      })
      .catch((err) => {});
  }, [clientId, reload]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", padding: "2rem" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">
          {addPackage
            ? "Formularz dodawania pakietu"
            : "Lista pakietów klienta"}
        </Typography>
        {!addPackage ? (
          <CustomButton
            type="add-package"
            label="Dodaj nowy"
            icon={<PersonAddIcon />}
            handleClick={handleOpenAddPackage}
          />
        ) : (
          <Button
            edge="start"
            sx={{ color: theme.palette.primary.main }}
            onClick={() => setAddPackage(false)}
            aria-label="close"
            endIcon={<EastIcon />}
          >
            Wróć
          </Button>
        )}
      </Box>
      {!addPackage ? (
        clientPackages.map((entry, index) => {
          return (
            <>
              <ListItemButton
                onClick={() => handleOpenList(index)}
                sx={{
                  backgroundColor: theme.palette.primary.white,
                  border: "solid",
                  borderBottom: openList === index ? "none" : "solid",
                  borderColor: theme.palette.primary.main,
                }}
              >
                <ListItemText
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                  primary={`${index + 1}. ${entry.package_name}`}
                  secondary={entry.package_type}
                />
                {openList === index ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse
                in={openList === index ? true : false}
                timeout="auto"
                unmountOnExit
                sx={{
                  border: "solid",
                  borderTop: openList === index ? "none" : "solid",
                  borderColor: theme.palette.primary.main,
                }}
              >
                <List
                  key={index}
                  sx={{
                    padding: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <ListItem>
                      <ListItemText
                        primary={`${format(
                          new Date(entry.insert_date),
                          "yyyy-MM-dd"
                        )}`}
                        secondary="Data wprowadzenia pakietu"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`${entry.start_date}`}
                        secondary="Data rozpoczęcia sprzedaży"
                      />
                    </ListItem>
                  </Box>
                  <ListItem>
                    <ListItemText
                      primary={`${entry.service_location}`}
                      secondary="Główna miejscowość wykonywania świadczeń zdrowotnych"
                    />
                  </ListItem>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <ListItem>
                      <ListItemText
                        primary={`${entry.net_price}`}
                        secondary="Stawka netto"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`${entry.gross_price}`}
                        secondary="Stawka brutto"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`${entry.net_price}`}
                        secondary="Stawka netto"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`${entry.VAT_rate}`}
                        secondary="Stawka VAT"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`${entry.VAT_price}`}
                        secondary="Kwota VAT"
                      />
                    </ListItem>
                  </Box>
                  {/* <ListItem>
                    <ListItemText
                      primary={`${entry.status}`}
                      secondary="Status pakietu"
                    />
                  </ListItem> */}

                  <Divider flexItem orientation="horizontal" />
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Grid container>
                            {entry.family_member_packages.map(
                              (entry, index) => {
                                return (
                                  <Grid item lg={4} lm={4} sm={4} sx={6}>
                                    <StyledTooltip
                                      key={index}
                                      arrow
                                      title={
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: "1rem",
                                            alignItem: "flex-start",
                                            border: "solid",
                                            borderColor:
                                              theme.palette.primary.main,
                                          }}
                                        >
                                          <Typography
                                            variant="p"
                                            sx={{
                                              textAlign: "left",
                                              padding: "0.5rem",
                                            }}
                                          >
                                            Imię: {entry.first_name}
                                          </Typography>
                                          <Divider
                                            flexItem
                                            orientation="horizontal"
                                          />
                                          <Typography
                                            variant="p"
                                            sx={{
                                              textAlign: "left",
                                              padding: "0.5rem",
                                            }}
                                          >
                                            Nazwisko: {entry.last_name}
                                          </Typography>
                                          <Divider
                                            flexItem
                                            orientation="horizontal"
                                          />
                                          <Typography
                                            variant="p"
                                            sx={{
                                              textAlign: "left",
                                              padding: "0.5rem",
                                            }}
                                          >
                                            Stopień pokrewieństwa:{" "}
                                            {entry.kinship === 1
                                              ? "Partner życiowy"
                                              : "Dziecko"}
                                          </Typography>
                                          <Divider
                                            flexItem
                                            orientation="horizontal"
                                          />
                                          <Typography
                                            variant="p"
                                            sx={{
                                              textAlign: "left",
                                              padding: "0.5rem",
                                            }}
                                          >
                                            Data urodzenia:{" "}
                                            {format(
                                              new Date(entry.birth_date),
                                              "dd-MM-yyyy"
                                            )}
                                          </Typography>
                                          <Divider
                                            flexItem
                                            orientation="horizontal"
                                          />
                                          <Typography
                                            variant="p"
                                            sx={{
                                              textAlign: "left",
                                              padding: "0.5rem",
                                            }}
                                          >
                                            Data wprowadzenia:{" "}
                                            {format(
                                              new Date(entry.insert_date),
                                              "dd-MM-yyyy"
                                            )}
                                          </Typography>
                                          <Divider
                                            flexItem
                                            orientation="horizontal"
                                          />
                                          <Typography
                                            variant="p"
                                            sx={{
                                              textAlign: "left",
                                              padding: "0.5rem",
                                            }}
                                          >
                                            {entry.pesel === "" &&
                                            entry.passport !== ""
                                              ? `Paszport: ${entry.passport}`
                                              : `PESEL: ${entry.pesel}`}
                                          </Typography>
                                          <Button
                                            onClick={() => {
                                              setFamilyMemberId(entry.id);
                                              setDeleteFamilyMemberSnackbar(
                                                true
                                              );
                                            }}
                                          >
                                            USUŃ
                                          </Button>
                                        </Box>
                                      }
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          margin: "0.5rem",
                                          padding: "0.5rem",
                                          cursor: "pointer",
                                          backgroundColor:
                                            theme.palette.primary.main,
                                        }}
                                      >
                                        {entry.first_name} {entry.last_name}
                                        <PersonIcon />
                                      </Box>
                                    </StyledTooltip>
                                  </Grid>
                                );
                              }
                            )}
                          </Grid>
                        </Box>
                      }
                      secondary="Członkowie rodziny"
                    />
                  </ListItem>
                  <Divider flexItem orientation="horizontal" />
                  <Button onClick={() => handleClickOpenFamilyMember(entry.id)}>
                    Dodaj członka rodziny
                  </Button>
                  {/* <Button>Edytuj pakiet</Button> */}
                  <Button
                    onClick={() => {
                      setPackageId(entry.id);
                      setDeletePackageSnackbar(true);
                    }}
                  >
                    Usuń pakiet
                  </Button>
                </List>
              </Collapse>
            </>
          );
        })
      ) : (
        <Box
          sx={{
            border: "solid",
            borderColor: theme.palette.primary.main,
            padding: "1rem",
          }}
        >
          {packagesDict && (
            <OperatorTabs
              packagesDict={packagesDict}
              packageFormData={packageFormData}
              setPackageFormData={setPackageFormData}
            />
          )}

          <DayPicker
            handleChange={handleChangeDate}
            label="Data rozpoczęcia sprzedaży"
          />
          <TextField
            autoFocus
            margin="dense"
            label="Główna miejscowość świadczenia usług"
            type="text"
            fullWidth
            variant="outlined"
            name="service_location"
            value={packageFormData.service_location}
            onChange={(event) =>
              setPackageFormData({
                ...packageFormData,
                service_location: event.target.value,
              })
            }
          />

          <CustomButton
            type="save"
            icon={<SaveIcon />}
            label="zapisz"
            loading={packageLoading}
            disabled={packageLoading}
            handleClick={handlePostClientPackage}
          />
        </Box>
      )}
      <FamilyMemberDialog
        setReload={setReload}
        open={openFamilyMember}
        handleClose={handleCloseFamilyMember}
        packageId={packageId}
        handleOpenSnackbar={handleOpenSnackbarPositive}
      />

      <SnackbarDelete
        isOpen={deletePackageSnackbar}
        handleCloseSnackbar={() => setDeletePackageSnackbar(false)}
        loading={packageLoading}
        disabled={packageLoading}
        snackbarMessage="Czy na pewno chcesz usunąć wybrany pakiet?"
        callbackOnConfirm={() => {
          setPackageLoading(true);
          DeleteClientPackage(packageId)
            .then(() => {
              setReload((prev) => !prev);
              setDeletePackageSnackbar(false);
            })
            .catch((err) => {})
            .finally(() => setPackageLoading(false));
        }}
      />
      <SnackbarDelete
        isOpen={deleteFamilyMemberSnackbar}
        handleCloseSnackbar={() => setDeleteFamilyMemberSnackbar(false)}
        snackbarMessage="Czy na pewno chcesz usunąć wybranego członka rodziny?"
        callbackOnConfirm={() => {
          DeleteFamilyMemberPackage(familyMemberId)
            .then(() => {
              setReload((prev) => !prev);
              setDeleteFamilyMemberSnackbar(false);
            })
            .catch((err) => {});
        }}
      />
    </Box>
  );
};

export default ClientPackages;
