import { api } from "../utils/AuthManager";

const GetPackagesDict = async (id) => {
  return api
    .get(`/api/package/`)

    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default GetPackagesDict;
