import { api } from "../utils/AuthManager";

const CheckBankName = async (bankNumber) => {
  return api
    .get(`/api/check_bank_name/`, { params: { bank_numb: bankNumber } })

    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default CheckBankName;
