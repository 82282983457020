import { api } from "../utils/AuthManager";

const PatchBankData = async (id, formData) => {
  return api
    .patch(`/api/change_bank_data/${id}`, formData)

    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default PatchBankData;
