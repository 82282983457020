import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { statuses } from "../../utils/StatusDict";
import { TableButton } from "../buttons/TableButton";
import CancelPaymentLink from "../../api/CancelPaymentLink";

const ClientNavbar = ({ clientId, clientData, close, reload, setReload }) => {
  const [status, setStatus] = useState();
  const [cancelLinkLoading, setCancelLinkLoading] = useState(false);
  const GetStatus = () => {
    let status = statuses.find((entry) => entry.id === clientData.status);
    return status.name;
  };
  const handleCancelLink = () => {
    setCancelLinkLoading(true);
    CancelPaymentLink(clientId)
      .then((res) => {
        if (res) {
          setReload((prev) => !prev);
        }
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setCancelLinkLoading(false);
      });
  };
  useEffect(() => {
    // console.log(clientData);
    setStatus(GetStatus());
  }, [reload, clientData]);
  return (
    <div>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography
            sx={{ ml: 2, flex: 1, color: "white", fontWeight: "regular" }}
            variant="h6"
            component="div"
          >
            Numer Klienta: {clientId}
          </Typography>
          <Typography
            sx={{ ml: 2, flex: 1, color: "white", fontWeight: "regular" }}
            variant="h6"
            component="div"
          >
            Status: {status && status}
            {clientData.status === 4 && (
              <Box sx={{ width: "10rem" }}>
                <TableButton
                  variant="cancel"
                  handleClick={handleCancelLink}
                  loading={cancelLinkLoading}
                />
              </Box>
            )}
          </Typography>
          <Typography variant="p" sx={{ color: "rgba(255,255,255,1)" }}>
            Wprowadził: {clientData.consultant.name}
          </Typography>
          <IconButton
            edge="start"
            sx={{ color: "white", marginLeft: "1rem" }}
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default ClientNavbar;
