import { useState, useEffect } from "react";
import PostFamilyMemberPackage from "../../api/PostFamilyMemberPackage";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import RadioButtons from "../buttons/RadioButtons";
import SaveIcon from "@mui/icons-material/Save";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
} from "@mui/material";
import CountrySelect from "./CountrySelector";

const FamilyMemberDialog = ({
  open,
  handleClose,
  packageId,
  setReload,
  handleOpenSnackbar,
}) => {
  const [isChecked, setIsChecked] = useState();
  const [isPassport, setIsPassport] = useState(false);
  const [country, setCountry] = useState({
    code: "PL",
    label: "Polska",
    phone: "48",
  });
  const [citizenShip, setCitizenShip] = useState({
    code: "PL",
    label: "Polska",
    phone: "48",
  });
  const handleSetCountry = (val) => {
    setFormData({
      ...formData,
      country: val.code,
    });
  };
  const handleSetCitizenship = (val) => {
    setFormData({
      ...formData,
      citizenship: val.code,
    });
  };
  const [formData, setFormData] = useState({
    package: "",
    first_name: "",
    last_name: "",
    pesel: "",
    birth_date: "",
    kinship: "",
    insert_date: new Date(),
    status: "",
    passport: "",
    country: "PL",
    citizenship: "PL",
  });

  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    pesel: "",
    error: "",
    birth_date: "",
    kinship: "",
  });

  const resetErrors = () => {
    setError({
      first_name: "",
      last_name: "",
      pesel: "",
      error: "",
      birth_date: "",
      kinship: "",
    });
  };

  const resetForms = () => {
    setFormData({
      package: "",
      first_name: "",
      last_name: "",
      pesel: "",
      birth_date: "",
      kinship: "",
      insert_date: new Date(),
      status: "",
      passport: "",
      country: "PL",
      citizenship: "PL",
    });
  };

  const handlePost = () => {
    let data = {
      ...formData,
      passport: formData.passport === "" ? null : formData.passport,
      pesel: formData.pesel === "" ? null : formData.pesel,
    };
    PostFamilyMemberPackage(packageId, data)
      .then((res) => {
        setReload((prev) => !prev);
        handleClose();
        resetErrors();
        resetForms();
        handleOpenSnackbar("Członek rodziny dodany pomyślnie");
      })
      .catch((err) => {
        setError(err.response.data.error.details);
      });
  };

  const parsePesel = () => {
    if (formData.pesel.length < 11) {
      return;
    } else {
      var aInt = new Array();
      for (let i = 0; i < 11; i++) {
        aInt[i] = parseInt(formData.pesel.substring(i, i + 1));
      }
      var wagi = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 1];
      var sum = 0;
      for (let i = 0; i < 11; i++) sum += wagi[i] * aInt[i];
      if (sum % 10 !== 0) {
        setError({ ...error, pesel: "Podany numer PESEL jest błędny" });
      }
      let year = 1900 + aInt[0] * 10 + aInt[1];
      if (aInt[2] >= 2 && aInt[2] < 8) year += Math.floor(aInt[2] / 2) * 100;
      if (aInt[2] >= 8) year -= 100;

      var month = (aInt[2] % 2) * 10 + aInt[3];
      var day = aInt[4] * 10 + aInt[5];
      setFormData({
        ...formData,
        birth_date: `${year}-${
          month.toString().length === 1 ? `0${month}` : month
        }-${day}`,
      });
    }
  };

  useEffect(() => {
    setFormData({ ...formData, kinship: "" });
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Dodaj członka rodziny</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Imię"
          type="text"
          fullWidth
          required
          variant="standard"
          name="first_name"
          helperText={error.first_name ? "To pole nie może być puste" : ""}
          error={error.first_name ? true : false}
          value={formData.first_name}
          onChange={(event) =>
            setFormData({ ...formData, first_name: event.target.value })
          }
        />
        <TextField
          autoFocus
          margin="dense"
          label="Nazwisko"
          type="text"
          fullWidth
          required
          variant="standard"
          name="last_name"
          helperText={error.last_name ? "To pole nie może być puste" : ""}
          error={error.last_name ? true : false}
          value={formData.last_name}
          onChange={(event) =>
            setFormData({ ...formData, last_name: event.target.value })
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isPassport}
              onChange={() => {
                setIsPassport((prev) => !prev);
                setFormData({ ...formData, passport: "", pesel: "" });
              }}
            />
          }
          label="Użyj paszportu zamiast numeru PESEL"
        />
        {isPassport ? (
          <TextField
            sx={{
              '& input[type="number"]::-webkit-inner-spin-button': {
                display: "none",
              },
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
            }}
            size="small"
            label="Paszport"
            variant="outlined"
            id="outlined-error"
            type="text"
            required
            // helperText={
            //   error.passport &&
            //   error.passport[0] ===
            //     "client with this passport already exists."
            //     ? "Klient o tym numerze paszportu już istnieje"
            //     : error.passport[0] === "This field may not be blank." &&
            //       "To pole nie może być puste"
            // }
            error={error.passport ? true : false}
            value={formData.passport}
            onChange={(event) => {
              setFormData({ ...formData, passport: event.target.value });
            }}
          />
        ) : (
          <TextField
            margin="dense"
            sx={{
              '& input[type="number"]::-webkit-inner-spin-button': {
                display: "none",
              },
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
            }}
            // size="small"
            fullWidth
            label="Pesel"
            variant="standard"
            id="outlined-error"
            type="number"
            required
            helperText={
              error.pesel
                ? error.pesel[0] === "Pesel already exists"
                  ? "Klient o tym numerze PESEL już istnieje"
                  : error.pesel[0] === "This field may not be blank."
                  ? "To pole nie może być puste"
                  : "Podany numer PESEL jest błędny"
                : "Naciśnij Enter, by uzupełnić datę urodzenia i płeć na podstawie peselu"
            }
            error={error.pesel ? true : false}
            value={formData.pesel}
            onKeyDown={parsePesel}
            onChange={(event) => {
              setFormData({ ...formData, pesel: event.target.value });
            }}
          />
        )}
        <TextField
          autoFocus
          margin="dense"
          label="Data urodzenia - YYYY-MM-DD"
          type="text"
          fullWidth
          required
          variant="standard"
          name="birth_date"
          helperText={
            error.birth_date
              ? error.birth_date[0] ===
                  "Date has wrong format. Use one of these formats instead: YYYY-MM-DD." &&
                "Błędny format daty"
              : "Podaj datę w formacie RRRR-MM-DD"
          }
          error={error.birth_date ? true : false}
          value={formData.birth_date}
          onChange={(event) =>
            setFormData({ ...formData, birth_date: event.target.value })
          }
        />
        <RadioButtons
          values={[
            { id: 1, name: "Partner życiowy" },
            { id: 2, name: "Dziecko" },
          ]}
          error={error.kinship}
          onChange={(event) => {
            setIsChecked(event.target.value);
            setFormData({ ...formData, kinship: event.target.value });
          }}
          isChecked={parseInt(isChecked)}
        />

        <CountrySelect
          label="Kraj pochodzenia"
          value={country}
          setValue={setCountry}
          handleSet={handleSetCountry}
        />
        <CountrySelect
          label="Obywatelstwo"
          value={citizenShip}
          setValue={setCitizenShip}
          handleSet={handleSetCitizenship}
        />
        {error.package && (
          <FormControl>
            <FormLabel error id="demo-radio-buttons-group-label">
              {error.package[0] ===
              "Only one FamilyMember can be added to Stomatologiczny package"
                ? "Do tego pakietu nie można przypisać więcej członków rodziny"
                : error.package[0] ===
                  "Indywidualny package cannot have FamilyMembers"
                ? "Do tego pakietu nie można przypisać członków rodziny"
                : error.package[0] ===
                    "Dziecko cannot be added to Partnerski package" &&
                  "Do pakietu partnerskiego nie można przypisać dziecka"}
            </FormLabel>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handlePost();
          }}
          endIcon={<SaveIcon />}
        >
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FamilyMemberDialog;
